import React from 'react'
import { Carousel } from 'react-bootstrap'

// images
import presenting1 from '../../images/presenting-1.jpg'
import presenting2 from '../../images/presenting-2.jpg'
import presenting3 from '../../images/presenting-3.jpg'
import presenting4 from '../../images/presenting-4.jpg'
import presenting5 from '../../images/presenting-5.jpg'
import presenting6 from '../../images/presenting-6.jpg'
import presenting7 from '../../images/presenting-7.jpg'
import presenting8 from '../../images/presenting-8.jpg'
import { CarouselImagesContainer } from './styles'

const allImages = [presenting1, presenting2, presenting3, presenting4, presenting5, presenting6, presenting7, presenting8];

const CarouselImages = () => (
  <CarouselImagesContainer>
    <Carousel interval={2000}>
    {allImages.map((image, index) => (
      <Carousel.Item key={index}>
        <img
          className="d-block w-100"
          src={image}
          alt={`Immagine ${index + 1}`}
        />
      </Carousel.Item>
    ))}
    </Carousel>
  </CarouselImagesContainer>
)

export default CarouselImages