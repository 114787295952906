import styled from "styled-components";

export const CarouselImagesContainer = styled.div`
  .carousel-control-prev {
    display: none;
  }
  .carousel-control-next {
    display: none;
  }
  .carousel-indicators {
    bottom: -20px;
  }

  img {
    max-width: 600px;
    max-height: 300px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
`