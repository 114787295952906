import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import TitleChecked from "../components/TitleChecked"
import { PageContainer } from "../components/PageContainer/styles";
import BannerInfo from "../components/BannerInfo";
import { theme } from "../theme"
import CarouselImages from "../components/CarouseImages"

const IndexPage = () => {

  return (<Layout>
    <SEO 
      title="Impresa Raf Service"
      description="Raf service offre servizi di pulizia, igiene ambientale, cura delle aree verdi e giardini, installazione di impianti di irrigazione"
    />
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12} style={{ marginBottom: theme.spacing.betweenRows }}>
            <TitleChecked title="Lavorare in un ambiente pulito e profumato significa svolgere al meglio la propria attività e offrire ai propri clienti un'immagine di serietà e di sicurezza" />
            <strong style={{ marginBottom: '15px', display: 'block'}}>Benvenuto sul sito di RAF SERVICE, un'impresa di pulizie e servizi per l'igiene ambientale</strong>
            <p>La qualità, la cura e la precisione negli interventi è garantita dalla professionalità del personale, dall'utilizzo dei migliori prodotti e attrezzature all'avanguardia, efficienti e silenziose</p>
            <p><strong>RAF SERVICE</strong> offre un servizio di pulizia e igiene a 360° e garantisce interventi di sanificazione personalizzati, in relazione alle esigenze del cliente e alla superficie da trattare.</p>
            <p><strong>RAF SERVICE</strong> fornisce strumenti e prodotti per l'igiene, ponendosi come unica interfaccia per garantire la massima salubrità degli ambienti in cui si vive e si lavora.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ marginBottom: theme.spacing.betweenRows }}>
            <CarouselImages />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TitleChecked title="RAF SERVICE non è solo pulizia, igiene ambientale e sanificazione" />
            <p style={{ marginBottom: '20px' }}>Oltre ai servizi classici di sanificazione di ambienti, <strong>RAF SERVICE</strong> offre anche:</p>
            <BannerInfo>
              <ul>
                <li>la cura di <strong>aree verdi</strong> interne ed esterne</li>
                <li>la manutenzione straordinaria di <strong>campi da calcio</strong> in erba e sintetici</li>
                <li>l'installazione di <strong>impianti di irrigazione</strong> a scomparsa</li>
              </ul>
            </BannerInfo>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default IndexPage
